@font-face {
  font-family: '#{$icon-font-family}';
  src:  url('#{$font-path}/#{$icon-font-family}.eot?h16cr');
  src:  url('#{$font-path}/#{$icon-font-family}.eot?h16cr#iefix') format('embedded-opentype'),
  url('#{$font-path}/#{$icon-font-family}.woff2?h16cr') format('woff2'),
  url('#{$font-path}/#{$icon-font-family}.ttf?h16cr') format('truetype'),
  url('#{$font-path}/#{$icon-font-family}.woff?h16cr') format('woff'),
  url('#{$font-path}/#{$icon-font-family}.svg?h16cr##{$icon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
}

@mixin inticon() {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Add this to make icons more flexible */
  display: inline-block;
  position: relative;
  top: 1px;
}

[class^="inticon-"], [class*=" inticon-"] {
  @include inticon();
}

.inticon-reload {
  &:before {
    content: $inticon-reload;
  }
}
.inticon-approve {
  &:before {
    content: $inticon-approve;
  }
}
.inticon-repair {
  &:before {
    content: $inticon-repair;
  }
}
.inticon-mail-forward {
  &:before {
    content: $inticon-mail-forward;
  }
}
.inticon-search-minus {
  &:before {
    content: $inticon-search-minus;
  }
}
.inticon-search-plus {
  &:before {
    content: $inticon-search-plus;
  }
}
.inticon-search {
  &:before {
    content: $inticon-search;
  }
}
.inticon-calculator {
  &:before {
    content: $inticon-calculator;
  }
}
.inticon-money-change {
  &:before {
    content: $inticon-money-change;
  }
}
.inticon-calendar-alt {
  &:before {
    content: $inticon-calendar-alt;
  }
}
.inticon-users {
  &:before {
    content: $inticon-users;
  }
}
.inticon-phone-clock {
  &:before {
    content: $inticon-phone-clock;
  }
}
.inticon-chart-composition {
  &:before {
    content: $inticon-chart-composition;
  }
}
.inticon-folder-alt {
  &:before {
    content: $inticon-folder-alt;
  }
}
.inticon-chart-comparison {
  &:before {
    content: $inticon-chart-comparison;
  }
}
.inticon-shield {
  &:before {
    content: $inticon-shield;
  }
}
.inticon-key {
  &:before {
    content: $inticon-key;
  }
}
.inticon-court {
  &:before {
    content: $inticon-court;
  }
}
.inticon-forward {
  &:before {
    content: $inticon-forward;
  }
}
.inticon-backward {
  &:before {
    content: $inticon-backward;
  }
}
.inticon-sign-out {
  &:before {
    content: $inticon-sign-out;
  }
}
.inticon-comment {
  &:before {
    content: $inticon-comment;
  }
}
.inticon-add {
  &:before {
    content: $inticon-add;
  }
}
.inticon-remove {
  &:before {
    content: $inticon-remove;
  }
}
.inticon-businesses {
  &:before {
    content: $inticon-businesses;
  }
}
.inticon-calendar {
  &:before {
    content: $inticon-calendar;
  }
}
.inticon-camera {
  &:before {
    content: $inticon-camera;
  }
}
.inticon-car {
  &:before {
    content: $inticon-car;
  }
}
.inticon-car-a {
  &:before {
    content: $inticon-car-a;
  }
}
.inticon-car-b {
  &:before {
    content: $inticon-car-b;
  }
}
.inticon-car-c {
  &:before {
    content: $inticon-car-c;
  }
}
.inticon-car-front {
  &:before {
    content: $inticon-car-front;
  }
}
.inticon-change-status {
  &:before {
    content: $inticon-change-status;
  }
}
.inticon-clients {
  &:before {
    content: $inticon-clients;
  }
}
.inticon-close {
  &:before {
    content: $inticon-close;
  }
}
.inticon-angle-up {
  &:before {
    content: $inticon-angle-up;
  }
}
.inticon-angle-right {
  &:before {
    content: $inticon-angle-right;
  }
}
.inticon-angle-down {
  &:before {
    content: $inticon-angle-down;
  }
}
.inticon-angle-left {
  &:before {
    content: $inticon-angle-left;
  }
}
.inticon-angle-up-circle {
  &:before {
    content: $inticon-angle-up-circle;
  }
}
.inticon-angle-right-circle {
  &:before {
    content: $inticon-angle-right-circle;
  }
}
.inticon-angle-down-circle {
  &:before {
    content: $inticon-angle-down-circle;
  }
}
.inticon-angle-left-circle {
  &:before {
    content: $inticon-angle-left-circle;
  }
}
.inticon-completed {
  &:before {
    content: $inticon-completed;
  }
}
.inticon-completed-circle {
  &:before {
    content: $inticon-completed-circle;
  }
}
.inticon-completed-full {
  &:before {
    content: $inticon-completed-full;
  }
}
.inticon-documents {
  &:before {
    content: $inticon-documents;
  }
}
.inticon-edit {
  &:before {
    content: $inticon-edit;
  }
}
.inticon-filter {
  &:before {
    content: $inticon-filter;
  }
}
.inticon-flashlight {
  &:before {
    content: $inticon-flashlight;
  }
}
.inticon-flashlight-circle {
  &:before {
    content: $inticon-flashlight-circle;
  }
}
.inticon-folder {
  &:before {
    content: $inticon-folder;
  }
}
.inticon-home {
  &:before {
    content: $inticon-home;
  }
}
.inticon-hotline {
  &:before {
    content: $inticon-hotline;
  }
}
.inticon-manuals {
  &:before {
    content: $inticon-manuals;
  }
}
.inticon-menu {
  &:before {
    content: $inticon-menu;
  }
}
.inticon-messages {
  &:before {
    content: $inticon-messages;
  }
}
.inticon-phone {
  &:before {
    content: $inticon-phone;
  }
}
.inticon-print {
  &:before {
    content: $inticon-print;
  }
}
.inticon-ruler {
  &:before {
    content: $inticon-ruler;
  }
}
.inticon-ruler-circle {
  &:before {
    content: $inticon-ruler-circle;
  }
}
.inticon-scan {
  &:before {
    content: $inticon-scan;
  }
}
.inticon-signature {
  &:before {
    content: $inticon-signature;
  }
}
.inticon-signature-checked {
  &:before {
    content: $inticon-signature-checked;
  }
}
.inticon-signature-unchecked {
  &:before {
    content: $inticon-signature-unchecked;
  }
}
.inticon-statistics-a {
  &:before {
    content: $inticon-statistics-a;
  }
}
.inticon-statistics-c {
  &:before {
    content: $inticon-statistics-c;
  }
}
.inticon-suitcase {
  &:before {
    content: $inticon-suitcase;
  }
}
.inticon-support-a {
  &:before {
    content: $inticon-support-a;
  }
}
.inticon-support-b {
  &:before {
    content: $inticon-support-b;
  }
}
.inticon-trash {
  &:before {
    content: $inticon-trash;
  }
}
.inticon-upload {
  &:before {
    content: $inticon-upload;
  }
}
.inticon-user {
  &:before {
    content: $inticon-user;
  }
}
.inticon-visability-autoclaim {
  &:before {
    content: $inticon-visability-autoclaim;
  }
}
.inticon-visability-csp {
  &:before {
    content: $inticon-visability-csp;
  }
}
.inticon-visability-lawyer {
  &:before {
    content: $inticon-visability-lawyer;
  }
}
.inticon-warning {
  &:before {
    content: $inticon-warning;
  }
}

// Increase font size for signature icon because it appears to small with the default font size.
.inticon-signature {
  font-size: 18px;
}
