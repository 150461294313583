$multiselect-option-highlight-bg: $primary !default;
$multiselect-option-selected-bg: $green !default;
$multiselect-option-selected-highlight-bg: darken($multiselect-option-selected-bg, 7) !default; // hover state
$multiselect-tag-bg: $green !default;
$multiselect-tag-border-radius: 5px !default;
$multiselect-tag-margin: 5px !default;
$multiselect-tag-padding: 9px !default;
$multiselect-tag-padding-left: 16px !default;
$multiselect-select-width: 40px !default;
$multiselect-single-height: 20px !default;
$multiselect-option-height : 30px !default;
$multiselect-select-height: $multiselect-single-height + ($multiselect-tag-padding * 2) !default; // Adjust manually with fontsize
$multiselect-height: $multiselect-select-height + ($border-width * 2) !default; // Add border for full input height
$multiselect-spinner-wrapper-width: 32px !default;
$multiselect-spinner-size: 16px !default;

fieldset[disabled] .multiselect {
  pointer-events: none;
}

.multiselect__spinner {
  position: absolute;
  right: 1px;
  top: 1px;
  width: $multiselect-spinner-wrapper-width;
  height: $multiselect-select-height;
  background: $input-bg;
  display: block;

  &:before,
  &:after {
    position: absolute;
    content: "";
    top: 50%;
    left: 50%;
    margin: (-$multiselect-spinner-size / 2) 0 0 (-$multiselect-spinner-size / 2);
    width: $multiselect-spinner-size;
    height: $multiselect-spinner-size;
    border-radius: 100%;
    border-color: $multiselect-tag-bg transparent transparent;
    border-style: solid;
    border-width: 2px;
    box-shadow: 0 0 0 1px transparent;
  }

  &:before {
    animation: spinning 2.4s cubic-bezier(0.41, 0.26, 0.2, 0.62);
    animation-iteration-count: infinite;
  }

  &:after {
    animation: spinning 2.4s cubic-bezier(0.51, 0.09, 0.21, 0.8);
    animation-iteration-count: infinite;
  }
}

.multiselect__loading-enter-active,
.multiselect__loading-leave-active {
  transition: opacity 0.4s ease-in-out;
  opacity: 1;
}

.multiselect__loading-enter,
.multiselect__loading-leave-active {
  opacity: 0;
}

.multiselect,
.multiselect__input,
.multiselect__single {
  font-family: $input-font-family;
  font-size: $input-font-size;
  touch-action: manipulation;
}

.multiselect {
  box-sizing: content-box;
  display: block;
  position: relative;
  width: 100%;
  text-align: left;
  color: $input-color;
}

.multiselect * {
  box-sizing: border-box;
}

.multiselect:focus {
  outline: none;
}

.multiselect--disabled {
  background: $input-disabled-bg;
  pointer-events: none;
  opacity: 0.6;
}

.multiselect--active {
  z-index: 50;
}

.multiselect--active:not(.multiselect--above) .multiselect__current,
.multiselect--active:not(.multiselect--above) .multiselect__input,
.multiselect--active:not(.multiselect--above) .multiselect__tags {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.multiselect--active .multiselect__select {
  //transform: rotateZ(180deg); // Disabled to unify all dropdown variants we use
}

.multiselect--above.multiselect--active .multiselect__current,
.multiselect--above.multiselect--active .multiselect__input,
.multiselect--above.multiselect--active .multiselect__tags {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.multiselect__input,
.multiselect__single {
  position: relative;
  display: inline-block;
  min-height: $multiselect-single-height;
  line-height: $multiselect-single-height;
  border: none !important;
  background: $input-bg;
  padding: 0;
  width: calc(100%);
  transition: border 0.1s ease;
  box-sizing: border-box;
  margin-bottom: $multiselect-tag-padding;
  vertical-align: top;

  @include border-radius($input-border-radius);
}

.multiselect__input::placeholder {
  color: #35495e;
}

.multiselect__tag ~ .multiselect__input,
.multiselect__tag ~ .multiselect__single {
  width: auto;
}

.multiselect__input:hover,
.multiselect__single:hover {
  border-color: #cfcfcf;
}

.multiselect__input:focus,
.multiselect__single:focus {
  border-color: #a8a8a8;
  outline: none;
}

.multiselect__single {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.multiselect__tags {
  min-height: $multiselect-height;
  display: block;
  padding: $multiselect-tag-padding $multiselect-select-width 0 $multiselect-tag-padding-left;
  border-radius: $border-radius;
  border: $border-width solid $input-border-color;
  background: $input-bg;
  font-size: $input-font-size;

  @include border-radius($multiselect-tag-border-radius);
}

.multiselect__tags-wrap {
  &:after {
    content: "";
    display: block;
    clear: both;
  }
}

.multiselect__tag {
  position: relative;
  display: inline-block;
  float: left;
  padding: 4px 26px 4px 10px;
  margin-right: $multiselect-tag-margin;
  color: #fff;
  line-height: 1;
  background: $multiselect-tag-bg;
  margin-bottom: $multiselect-tag-margin;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  font-size: $font-size-sm;
  @include border-radius($multiselect-tag-border-radius);
}

.multiselect__tag-icon {
  cursor: pointer;
  margin-left: 7px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  font-weight: 700;
  font-style: initial;
  width: 22px;
  text-align: center;
  line-height: 22px;
  transition: all 0.2s ease;

  @include border-radius($multiselect-tag-border-radius);

  &:after {
    content: "×";
    position: relative;
    top: -1px;
    color: white;
    opacity: 0.5;
    font-size: 14px;
  }

  &:hover,
  &:focus {
    background-color: darken($multiselect-tag-bg, 7);
    &:after {
      opacity: 1;
    }
  }
}

.multiselect__current {
  line-height: $font-size-base;
  min-height: $multiselect-height;
  box-sizing: border-box;
  display: block;
  overflow: hidden;
  padding: $multiselect-tag-padding 12px 0;
  padding-right: 30px;
  white-space: nowrap;
  margin: 0;
  text-decoration: none;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  cursor: pointer;
}

.multiselect__select {
  line-height: $font-size-base;
  display: block;
  position: absolute;
  box-sizing: border-box;
  width: $multiselect-select-width;
  height: $multiselect-select-height;
  right: $border-width;
  top: $border-width;
  padding: 0 $multiselect-tag-padding;
  margin: 0;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.chosen-container-single .chosen-single div b:before, // Match chosen caret to multiselect
.multiselect__select:before {
  @include inticon;
  content: $inticon-angle-down;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #999;
}

.multiselect__placeholder {
  color: #adadad;
  display: inline-block;
  position: relative;
  top: -($multiselect-tag-padding / 3);
  line-height: $font-size-base;
}

.multiselect--active .multiselect__placeholder {
  display: none;
}

.multiselect__content-wrapper {
  position: absolute;
  display: block;
  background: #fff;
  width: 100%;
  max-height: 240px;
  overflow: auto;
  border: $border-width solid $input-border-color;
  border-top: none;
  z-index: 50;
  -webkit-overflow-scrolling: touch;
}

.multiselect__content {
  list-style: none;
  display: inline-block;
  padding: 0;
  margin: 0;
  min-width: 100%;
  vertical-align: top;
  max-width: 100%;
}

.multiselect--above .multiselect__content-wrapper {
  bottom: 100%;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: none;
  border-top: 1px solid #e8e8e8;
}

.multiselect__content::webkit-scrollbar {
  display: none;
}

.multiselect__element {
  display: block;
}

.multiselect__option {
  display: block;
  position: relative;
  padding: $multiselect-tag-padding;
  min-height: $multiselect-option-height;
  line-height: $multiselect-option-height;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  position: relative;
  cursor: pointer;
  white-space: nowrap;

  &:after {
    top: 0;
    right: 0;
    position: absolute;
    line-height: $multiselect-option-height + ($multiselect-tag-padding * 2);
    padding-right: $multiselect-tag-padding;
    padding-left: 20px;
    font-size: $font-size-sm;
  }
}

.multiselect__option--highlight {
  background: $multiselect-option-highlight-bg;
  outline: none;
  color: white;

  &:after {
    content: attr(data-select);
    background: $multiselect-option-highlight-bg;
    color: white;
  }
}

.multiselect__option--selected {
  background: $multiselect-option-selected-bg;
  color: $white;

  &:after {
    content: attr(data-selected);
    background: $multiselect-option-selected-bg;
    color: $white;
  }
}

.multiselect__option--selected.multiselect__option--highlight {
  background: $multiselect-option-selected-highlight-bg;
  color: $white;

  &:after {
    background: $multiselect-option-selected-highlight-bg;
    content: attr(data-deselect);
    color: $white;
  }
}

.multiselect--disabled {
  .multiselect__current,
  .multiselect__select {
    background: #ededed;
    color: #a6a6a6;
  }
}

.multiselect__option--disabled {
  background: #ededed !important;
  color: #a6a6a6 !important;
  cursor: text;
  pointer-events: none;
}

.multiselect__option--group {
  background: #ededed;
  color: #35495e;

  .multiselect__option--highlight {
    background: #35495e;
    color: #fff;

    &:after {
      background: #35495e;
    }
  }
}

.multiselect__option--disabled.multiselect__option--highlight {
  background: #dedede;
}

.multiselect__option--group-selected.multiselect__option--highlight {
  background: #ff6a6a;
  color: #fff;
}

.multiselect__option--group-selected.multiselect__option--highlight:after {
  background: #ff6a6a;
  content: attr(data-deselect);
  color: #fff;
}

.multiselect-enter-active,
.multiselect-leave-active {
  transition: all 0.15s ease;
}

.multiselect-enter,
.multiselect-leave-active {
  opacity: 0;
}

.multiselect__strong {
  margin-bottom: 8px;
  line-height: 20px;
  display: inline-block;
  vertical-align: top;
}

*[dir="rtl"] .multiselect {
  text-align: right;
}

*[dir="rtl"] .multiselect__select {
  right: auto;
  left: 1px;
}

*[dir="rtl"] .multiselect__tags {
  padding: $multiselect-tag-padding $multiselect-tag-padding 0 $multiselect-select-width;
}

*[dir="rtl"] .multiselect__content {
  text-align: right;
}

*[dir="rtl"] .multiselect__option:after {
  right: auto;
  left: 0;
}

*[dir="rtl"] .multiselect__clear {
  right: auto;
  left: 12px;
}

*[dir="rtl"] .multiselect__spinner {
  right: auto;
  left: 1px;
}

@keyframes spinning {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(2turn);
  }
}
