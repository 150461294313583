.tablePlaceHolder {
  overflow-x: auto;
}

table {
  .widgetList {
    display: flex;
    margin-bottom: 0;
  }

  .actions,
  .action,
  .status {
    width: 1px;
  }

  tr.interactive {
    &:hover {
      td {
        cursor: pointer;
      }
    }
  }
}
