label {
  margin-bottom: 0;
}

// Disable Bootstrap's magic for displaying invalid feedback only if there is an invalid field nearby.
.invalid-feedback {
  display: block;
}

.document-row .form-group {
  margin-bottom: 0;
}

// Fix disabled checked and radio borders

.custom-checkbox,
.custom-radio {
  .custom-control-input:disabled {
    &:checked ~ .custom-control-label::before {
      border-color: $custom-control-indicator-checked-disabled-border-color;
    }
  }
}

.custom-switch {
  .custom-control-label::after {
    background-color: $white;
  }
}

.buttonset {
  .form-check {
    padding-left: 0;
  }

  .form-check-label {
    padding-top: 0;
  }
}

.border-yellow {
  border: 2px solid #ffaf00 !important;
}

.record-detail-notes .btn-group-toggle label {
  background-color: $white;
  border-color: $gray-600;
  border-radius: 5px;
  color: $gray-600;
}

.record-detail-notes .note-form .btn-secondary:not(:disabled):not(.disabled).active {
  background-color: #084150;
  border-color: #084150;
  font-weight: bold;
  color: #fff;
}

#Csp_Form_VehicleDamageRecordCostUnitInsert .hide .ui-state-active,
#Damage_Form_AddNote #fieldset-advancedOptions .hide .ui-state-active,
#fileAdd .hide .ui-state-active {
  background-color: $state-color-red;
  border-color: $state-color-red;
}

.btn-note-priority {
  color: $state-color-red;
  border-color: $state-color-red;
  background-color: transparent;
  border-radius: $btn-border-radius;
  border-width: 3px;
  padding: 0.1rem 0.4rem;
}
.btn-note-priority:hover, .btn-note-priority:active, .btn-note-priority:focus{
  background-color: $state-color-red !important;
  border-color: $state-color-red !important;
}

.firm-branch-user-assignment-highlight {
  color: #ffaf00;
}
