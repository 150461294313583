.invoicing-modality-column-narrow {
  width: 10em;
}

.invoicing-modality-column-wide {
  width: 50em;
}

.invoicing-modality-column-car-dealer-article {
  /* should be the sum of the column widths for the article and service recipient columns in the
     service provider and appraiser tables */
  width: 60em;
}

.invoicing-modality-container {
  /* uses the sum of the widths of all table columns */
  width: 130em;
}
