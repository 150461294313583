.card {
  &.card-foldable {
    .foldable-header {
      margin-bottom: 0;

      &:not(.collapsed) {
        margin-bottom: 1rem;
      }
    }
  }
}
