.role-group-badge {
  line-height: 12px;
  width: 24px;

  table & {
    margin-right: 2px;
  }
}

.role-group-badge {
  &.toggleVisibility {
    cursor: pointer;
  }

  &.transparent {
    opacity: 0.25;
    filter: Alpha(Opacity=25);
  }
}

td.role-group-badge {
  width: 85px;

  div {
    float: left;
  }
}
