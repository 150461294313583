.record-state-overview-wrapper,
.record-state-overview-wrapper * {
  box-sizing: border-box;
}

.record-state-info-text {
  padding-top: 10px;
  cursor: default;
}

.record-state-overview {
  display: flex;
}

.role-group-badge,
.record-state-badge {
  font-size: $state-font-size;
  text-align: center;
  text-transform: uppercase;
  // We must use important here because in the desktop application the ui-widget styles would overwrite this.
  color: #ffffff !important;
  float: left;
  background-color: $state-color-neutral;
  border: 1px solid $state-color-neutral;
}

.record-state-badge {
  margin-right: $state-badge-margin-right;
  padding: $state-badge-padding;
  width: $state-badge-width;

  &.has-icon {
    font-size: $state-icon-font-size;
    width: $state-icon-font-width;
    line-height: 1;
  }

  &:last-child {
    margin-right: 0;
  }

  &.red {
    background-color: $state-color-red;
    border-color: $state-color-red;
  }

  &.yellow {
    background-color: $state-color-yellow;
    border-color: $state-color-yellow;
  }

  &.green {
    background-color: $state-color-green;
    border-color: $state-color-green;
  }

  &.green-with-red-border {
    background-color: $state-color-green;
    border-color: $state-color-red;
  }

  &.light {
    background-color: #ffffff;
    border: 1px solid #aaa8a1;
    color: #999 !important;;
  }
}
