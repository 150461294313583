// Inticon
$icon-font-family: "inticon-csp";

$inticon-reload: "\e907";
$inticon-approve: "\e908";
$inticon-repair: "\e909";
$inticon-mail-forward: "\e903";
$inticon-search-minus: "\e904";
$inticon-search-plus: "\e905";
$inticon-search: "\e906";
$inticon-calculator: "\e943";
$inticon-money-change: "\e944";
$inticon-calendar-alt: "\e945";
$inticon-users: "\e946";
$inticon-phone-clock: "\e947";
$inticon-chart-composition: "\e948";
$inticon-folder-alt: "\e949";
$inticon-chart-comparison: "\e94a";
$inticon-shield: "\e94b";
$inticon-key: "\e94c";
$inticon-court: "\e937";
$inticon-forward: "\e936";
$inticon-backward: "\e901";
$inticon-sign-out: "\f08b";
$inticon-comment: "\f0e5";
$inticon-add: "\e900";
$inticon-remove: "\e938";
$inticon-businesses: "\e902";
$inticon-calendar: "\e90c";
$inticon-camera: "\e90d";
$inticon-car: "\e90e";
$inticon-car-a: "\e90f";
$inticon-car-b: "\e910";
$inticon-car-c: "\e911";
$inticon-car-front: "\e912";
$inticon-change-status: "\e913";
$inticon-clients: "\e914";
$inticon-close: "\e915";
$inticon-angle-up: "\e916";
$inticon-angle-right: "\e932";
$inticon-angle-down: "\e917";
$inticon-angle-left: "\e933";
$inticon-angle-up-circle: "\e918";
$inticon-angle-right-circle: "\e919";
$inticon-angle-down-circle: "\e934";
$inticon-angle-left-circle: "\e935";
$inticon-completed: "\e91a";
$inticon-completed-circle: "\e91b";
$inticon-completed-full: "\e91c";
$inticon-documents: "\e91d";
$inticon-edit: "\e91e";
$inticon-filter: "\e91f";
$inticon-flashlight: "\e920";
$inticon-flashlight-circle: "\e921";
$inticon-folder: "\e922";
$inticon-home: "\e923";
$inticon-hotline: "\e924";
$inticon-manuals: "\e925";
$inticon-menu: "\e926";
$inticon-messages: "\e927";
$inticon-phone: "\e928";
$inticon-print: "\e929";
$inticon-ruler: "\e92a";
$inticon-ruler-circle: "\e92b";
$inticon-scan: "\e92c";
$inticon-signature: "\e92d";
$inticon-signature-checked: "\e92e";
$inticon-signature-unchecked: "\e92f";
$inticon-statistics-a: "\e930";
$inticon-statistics-c: "\e931";
$inticon-suitcase: "\e939";
$inticon-support-a: "\e93a";
$inticon-support-b: "\e93b";
$inticon-trash: "\e93c";
$inticon-upload: "\e93d";
$inticon-user: "\e93e";
$inticon-visability-autoclaim: "\e93f";
$inticon-visability-csp: "\e940";
$inticon-visability-lawyer: "\e941";
$inticon-warning: "\e942";
