#messages-sidebar {
  position: absolute;
  right: 0;
  top: 50px;
  width: 0;
  min-height: 100%;
  background-color: $sidebar-background-color;

  &,
  * {
    box-sizing: border-box;
  }

  .overflow-container {
    overflow-x: hidden;
  }

  .fixed-width-container {
    width: $messages-sidebar-width;
  }

  .toggle-button {
    position: absolute;
    width: 20px;
    height: 26px;
    left: -20px;
    top: 0;
    padding-top: 5px;
    background-color: $primary;
    color: $white;
    content: "";
    cursor: pointer;
    text-align: center;

    .inticon {
      font-size: 14px;
    }
  }
}

#layout.messages-sidebar-expanded {
  #contentHeader {
    margin-right: 0;
  }

  #main {
    width: calc(100% - 180px - #{$messages-sidebar-width} - 13px);
  }

  #messages-sidebar {
    width: $messages-sidebar-width;
    right: 0;
    z-index: 5;

    .toggle-button {
      left: 0;

      .inticon {
        transform: rotate(180deg);
      }
    }
  }
}
