.vtl {
  .vtl-drag-disabled {
    background-color: #d0cfcf;

    &:hover {
      background-color: #d0cfcf;
    }
  }

  .vtl-disabled {
    background-color: #d0cfcf;
  }

  .vtl-node-main {
    &:hover {
      cursor: pointer;
    }

    .icon {
      margin-right: 5px;

      &.icon-hidden {
        display: none;
      }
    }
  }

  .vtl-operation {
    .icon:hover {
      cursor: pointer;
      color: $primary;
    }
  }

  .vtl-icon:hover {
    color: $primary;
  }
}

.muted {
  color: $gray-600;
  font-size: 80%;
}
