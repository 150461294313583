@import "./scss/imports/variables";
@import "./scss/imports/bootstrap";
@import "./scss/imports/bootstrap-vue";

@import "../../compass/import/theme/default/import/variables";
@import "~@scss/imports/inticon-variables";
@import "~@scss/imports/inticon";
@import "~@scss/partials/utilities";

@import "~@scss/partials/forms";
@import "scss/partials/forms";
@import "./scss/partials/layout";
@import "~@scss/partials/typo";
@import "./scss/partials/messages-sidebar";
@import "./scss/partials/record/cost-units";
@import "./scss/partials/record/recordstate";
@import "./scss/partials/firmlevel";
@import "./scss/partials/role-groups";
@import "./scss/partials/table";
@import "~@scss/components/select-searchable";
@import "~@scss/components/tree-select";
@import "./scss/partials/qr-code";
@import "~@scss/partials/cards";
@import "./scss/partials/cards";
@import "~@scss/partials/loading";
@import "./scss/partials/receipt";
@import "./scss/partials/invoicing-modality";
@import "./scss/partials/note-calendar";

#damageRecordSelect {
  td {
    // This is a hack to limit the scope of the stretched links to the current column.
    transform: rotate(0);
  }
}

#fieldset-lawyersAssignments {
  .nc24FormElement {
    &.lawyersAssignmentsIds {
      padding: 0 !important;
      border: 0 !important;
    }
  }
}

.record-table-file-drop-buttons {
  .category-button {
    font-size: 20pt;
    color: $autoclaim-cd-blue-1;
    cursor: pointer;
    border-width: 0 1.5rem;
    border-style: none none none solid;
  }

  .category-button-border {
    border: 0.35rem solid $autoclaim-cd-gray-2;
  }
}

.widgetList {
  .btn-jquery {
    margin-right: 0.1rem;
    margin-bottom: 0.1rem;
  }
}

.tooltip-inner {
  background-color: #fff;
  color: #000;
  border: 1px solid #a6a6a6;
  border-radius: 4px;
}

// from newer versions of bootstrap
.fw-bold {
  font-weight: bold;
}
