.qr-code-page {
  min-height: 700px;

  .qr-code-box {
    margin: 100px 200px;
    border: 2px solid $success;
    padding: 40px;
  }

  .qr-code-img-outer-wrapper {
    background-color: $primary;
    padding: 10px;
    border-radius: 10px;
    width: 160px;
  }

  .qr-code-img-outer-wrapper-large {
    background-color: $primary;
    padding: 10px;
    border-radius: 10px;
    width: 280px;
  }

  .qr-code-img-inner-wrapper {
    background-color: #f7f7f8;
    border-radius: 10px;

    img {
      margin: 10px;
    }
  }

  .qr-code-title {
    font-size: 18px;
    margin-top: 10px;
    color: $white;
  }
}
