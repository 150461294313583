// Custom

$brand-icon-size: 180px;
$brand-icon-size-lg: 240px;
$font-size-social-media: 28px;
$font-size-social-media-lg: 38px;
$subnav-item-padding-vertical: 0.3rem;
$subnav-wrapper-padding: 0.3rem;
$navbar-height: 118px;
$infobox-icon-container-size: 70px;
$card-custom-footer-padding-y: 0.3rem;
$headings-margin-top: 2em;
$icon-spacing-default: 0.5em;
$date-stamp-size: 50px;
$navbar-nav-link-counter-width: 26px;
$navbar-nav-link-counter-height: 20px;
$image-upload-icon-counter-width: 26px;
$image-upload-icon-counter-height: 20px;
$image-upload-icon-padding-x: 0.5rem !default;

// Paths
$image-path: "images";

// The path is relative to the resources/scss/imports/_inticon.scss file
$font-path: "../../../public/static/font/inticon";

// Color system

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6f6f6f;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$grays: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge((
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900
),
$grays);

$blue: #0076bd;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #b81216;
$orange: #f0593e;
$yellow: #feca0a;
$green: #55ab26;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;

$primary: #0b5e73;
$secondary: #032f3f; // original #4a7389
$tertiary: #87857d;
$success: $green;
$info: #1a96b5;
$warning: #ffaf00;
$danger: $orange;
$light: $gray-200 !default;
$dark: $gray-800 !default;
$highlighted: #dbe6c4;

$theme-colors: (
  "tertiary": $tertiary
);

$status-ring-gray: $gray-700;
$status-ring-green: $success;
$status-ring-yellow: $warning;
$status-ring-white: $white;
$status-icon-highlight: $red;

// Autoclaim CD colors

$autoclaim-cd-blue-1: #0B5E73;
$autoclaim-cd-blue-2: #032F3F;
$autoclaim-cd-blue-3: #1A96B5;
$autoclaim-cd-green-1: #55AB26;
$autoclaim-cd-green-2: #00CC00;
$autoclaim-cd-gray-1: #CDCCC7;
$autoclaim-cd-gray-2: #A5A39B;
$autoclaim-cd-gray-3: #F7F7F8;
$autoclaim-cd-signal-orange: #FFAF00;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark: $gray-700;
$yiq-text-light: $white !default;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-rounded: false !default;

$border-radius: 0;

$btn-border-radius: 5px;
$btn-border-radius-lg: 5px;
$btn-border-radius-sm: 5px;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge((
  0: 0,
  1: ($spacer * 0.25),
  2: ($spacer * 0.5),
  3: $spacer,
  4: ($spacer * 1.5),
  5: ($spacer * 3)
),
$spacers);

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$sizes: map-merge((
  25: 25%,
  50: 50%,
  75: 75%,
  100: 100%,
  auto: auto
),
$sizes);

$form-group-margin-bottom: 1rem;


// Body
//
// Settings for the `<body>` element.

$body-bg: #f7f7f8 !default;
$body-color: $tertiary !default;


// Links
//
// Style anchor elements.

$link-color: $secondary !default;
$link-decoration: none !default;
$link-hover-color: darken($link-color, 15%) !default;
$link-hover-decoration: underline !default;
// Darken percentage for links with `.text-*` class (e.g. `.text-success`)
$emphasized-link-hover-darken-percentage: 15% !default;

// Paragraphs
//
// Style p element.

$paragraph-margin-bottom: 1rem !default;


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12 !default;
$grid-gutter-width: 30px !default;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-sans-serif: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base: $font-family-sans-serif !default;
// stylelint-enable value-keyword-case

$font-size-base: 1rem !default;
$font-size-lg: $font-size-base * 1.25 !default;
$font-size-sm: $font-size-base * 0.875 !default;
$font-size-uc: 12px;
$font-size-lead: $font-size-base * 1.15;

$font-weight-lighter: lighter !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 700 !default;
$font-weight-bolder: bolder !default;

$font-weight-base: $font-weight-normal !default;
$line-height-base: 1.6;

$h1-font-size: $font-size-base * 2.5 !default;
$h2-font-size: $font-size-base * 1.8 !default;
$h3-font-size: $font-size-base * 1.6 !default;
$h4-font-size: $font-size-base * 1.4 !default;
$h5-font-size: $font-size-base * 1.25 !default;
$h6-font-size: $font-size-base !default;

$headings-margin-bottom: $spacer;
$headings-font-family: null !default;
$headings-font-weight: $font-weight-bold;
$headings-line-height: 1.2 !default;
$headings-color: $primary;

// Navs

$nav-link-padding-y: 0.5rem !default;
$nav-link-padding-x: 1rem !default;
$nav-link-disabled-color: $gray-600 !default;

// Navbar

$navbar-padding-y: $grid-gutter-width / 4;
$navbar-padding-x: 0;

$navbar-nav-link-padding-x: 0.5rem !default;

$navbar-brand-font-size: $font-size-lg !default;
// Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link
$nav-link-height: $font-size-base * $line-height-base + $nav-link-padding-y * 2 !default;
$navbar-brand-height: $navbar-brand-font-size * $line-height-base !default;
$navbar-brand-padding-y: ($nav-link-height - $navbar-brand-height) / 2 !default;


// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-link-color: $gray-600;
$dropdown-link-hover-color: $dropdown-link-color;
$dropdown-link-hover-bg: $gray-200;
$dropdown-link-disabled-color: $gray-400;
$dropdown-item-padding-x: 1rem;


// Breadcrumbs

$breadcrumb-padding-y: 0.75rem !default;
$breadcrumb-padding-x: 0;
$breadcrumb-item-padding: 0.5rem !default;

$breadcrumb-margin-bottom: 0;

$breadcrumb-bg: transparent;
$breadcrumb-divider-color: $gray-400;
$breadcrumb-active-color: $gray-600 !default;
$breadcrumb-divider: quote("〉") !default;

// Cards

$card-spacer-y: $grid-gutter-width / 2;
$card-spacer-x: $form-group-margin-bottom;

$card-columns-gap: $grid-gutter-width / 2;

// List group

$list-group-item-padding-y: 0.5rem;
$list-group-item-padding-x: 0.75rem;

// mmenu overrides
//	Colors
/* stylelint-disable scss/dollar-variable-pattern */
$mm_backgroundColor: $primary;
$mm_borderColor: rgba(#fff, 0.1) !default;
$mm_dimmedTextColor: $white;
$mm_emphasizedBackgroundColor: lighten($primary, 10);
$mm_highlightedBackgroundColor: $white;
$mm_navbarColor: $white;
$mm_textColor: $white;
$mm_fontSize: 14px;
/* stylelint-enable scss/dollar-variable-pattern */

:root {
  /** Size for the button. */
  --mb-button-size: 40px;

  /** Width for the bars, relative to the button. */
  --mb-bar-width: 0.6;

  /** Height for the bars.*/
  --mb-bar-height: 3px;

  /** Distance between bars (approximately). */
  --mb-bar-spacing: 5px;
}


$embed-responsive-aspect-ratios: (
  (21 9),
  (16 9),
  (4 3),
  (74 17),
  (36 17),
  (1 1)
);

$input-color: $gray-700 !default;
$input-bg: #e3e2dd !default;
$input-border-color: #e3e2dd !default;
$input-placeholder-color: lighten($input-color, 30%);
$custom-control-indicator-border-color : $input-border-color;
$custom-control-indicator-checked-disabled-border-color: transparent;

//Modals
$modal-xl: 1140px;
$modal-lg: 800px;
$modal-md: 575px;
$modal-sm: 300px;

$modal-content-border-radius: 0;
$modal-content-border-width: 5px;
$modal-content-border-color: $secondary;
$modal-header-border-width: 0;
$modal-content-inner-border-radius: 0;

$toast-background-color: $white;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

$state-font-size: 12px !default;
$state-badge-width: 36px !default;
$state-badge-padding: 2px !default;
$state-badge-margin-right: 3px !default;
$state-icon-font-size: 18px !default;
$state-icon-font-width: 28px !default;

$state-color-neutral: #a5a39b;
$state-color-red: #e12b97;
$state-color-yellow: #ffaf00;
$state-color-green: #55ab24;
