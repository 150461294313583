.break-lines {
  white-space: pre-line;
}

// Hide native arrows for number inputs
/* stylelint-disable property-no-vendor-prefix */
.hide-arrows {
  /* Firefox */
  -moz-appearance: textfield;

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
/* stylelint-enable property-no-vendor-prefix */
