html {
  font-size: 12px;
}

// Here we fix styles that are broken by jQuery UI widget styles.
// This can be dropped when jQuery UI is gone.
.root-component {
  @include font-size($font-size-base);

  .table-sm {
    th,
    td {
      padding: $table-cell-padding-sm;
    }
  }
}

#layout {
  #main {
    min-width: auto;
    width: calc(100% - 180px);
  }
}

#layout #main,
#messages-sidebar {
  transition: width $sidebar-transition-duration $sidebar-transition-ease;
}

#contentHeader,
.toggle-button,
.toggle-button .inticon {
  transition: all $sidebar-transition-duration $sidebar-transition-ease;
}

.controllerHeadline,
.navigationSection,
.sidebar-header {
  font-size: 12px;
  font-weight: bold;
  padding: 6px;
  text-align: center;
  background-color: $primary;
  color: $white;
  line-height: normal;
  text-transform: uppercase;
}

h1.controllerHeadline {
  margin-bottom: 5px;
  min-height: 30px;

  .text {
    vertical-align: middle;
  }
}

#contentHeader {
  margin-right: 30px;
}

@mixin button-variant-jquery($background, $border, $hover-background: $border, $hover-border: $border, $active-background: $primary, $active-border: $primary) {
  @include gradient-bg($background);
  @include box-shadow($btn-box-shadow);
  color: $btn-jquery-color;
  border-color: $border;

  @include hover() {
    @include gradient-bg($hover-background);
    color: color-yiq($hover-background);
    border-color: $hover-border;
  }

  &:focus,
  &.focus {
    @include gradient-bg($hover-background);
    border-color: $hover-border;
    // Avoid using mixin so we can pass custom focus shadow properly
    @if $enable-shadows {
      box-shadow: $btn-box-shadow, 0 0 0 $btn-focus-width rgba(mix(color-yiq($background), $border, 15%), 0.5);
    } @else {
      box-shadow: 0 0 0 $btn-focus-width rgba(mix(color-yiq($background), $border, 15%), 0.5);
    }
  }
}

.btn-jquery {
  @include button-variant-jquery($btn-jquery-bg, $btn-jquery-border-color);
  font-weight: bold;
  padding: 0.4em 1em;

  &.btn-icon {
    font-size: 16px;
    padding: 2px 3px;
    line-height: 1;
  }

  .inticon {
    margin-right: 5px;
  }
}

.ui-widget {
  font-size: 1rem;
}

.ui-widget-content a {
  color: $link-color;
}

a.button,
a.ui-button {
  @include hover-focus() {
    color: $btn-jquery-color;
  }
}

.symbol_search {
  font-size: 20px;
  color: $gray-600;
}

td.visibility {
  min-width: 125px;
}

.nav-tabs {
  .nav-link {
    color: $secondary;
    margin-bottom: 0;
    font-weight: 700;
    border: 1px solid $nav-tabs-border-color;
    border-bottom: 0 none;

    &.active:hover {
      background-color: darken($nav-tabs-link-active-bg, 7.5%);
    }
  }
}

.firmgroup-firm-list {
  margin-top: 5rem;

  ul {
    list-style: disc;
    padding: revert;
  }
}

.record-file-expand-link,
.record-file-expand-link:hover {
  color: $state-color-red;
}

.font-size-full {
  font-size: 100% !important;
}
