.nc24Form {
  label {
    margin-bottom: 0;
  }

  legend {
    font-size: 10px;
    width: auto;
    padding: 0 2px;
    margin-bottom: 0;
  }
}

.form-check-label {
  padding-top: 4px;
}

.form-hint {
  padding-left: 4px;
}

.nc24FormElement.inquiry,
.nc24FormElement.hide {
  .ui-button-text-only .ui-button-text {
    padding: 3px 1em 2px;
  }
}
