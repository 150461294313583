/**
 * Dependencies
 */

@import "~material-colors/dist/colors";
@import "~easings-css/easings";

/**
 * Variables
 */

$treeselect-font-size: $input-font-size;
$treeselect-font-size-sm: $input-font-size * 0.75;
$treeselect-padding: 5px;
$treeselect-border-radius-lg: $border-radius;
$treeselect-border-radius-sm: $border-radius;
$treeselect-narrow-cell-width: 20px;
$treeselect-wide-cell-width: 100%;
$treeselect-icon-size: 12px;
$treeselect-transition-duration: 200ms;
$treeselect-transition-duration-slow: 400ms;
$treeselect-max-level: 8;

$treeselect-control-single-height: 34px !default;
$treeselect-control-height:  $treeselect-control-single-height + ($border-width * 2);
$treeselect-control-border-width: 1px;
$treeselect-control-inner-height: $treeselect-control-height - $treeselect-control-border-width * 2;
$treeselect-control-border-color: #ddd;
$treeselect-control-border-color-hover: #cfcfcf;
$treeselect-control-border-color-active: $treeselect-control-border-color-hover;
$treeselect-control-border-color-focus: $md-light-blue-600;
$treeselect-control-border-radius: $treeselect-border-radius-lg;
$treeselect-control-bg: $input-bg;
$treeselect-control-box-shadow-focus: 0 0 0 3px fade($treeselect-control-border-color-focus, 10%);
$treeselect-control-bg-disabled: #f9f9f9;

$treeselect-placeholder-font-color: $md-grey-400;
$treeselect-single-value-font-color: #333;
$treeselect-single-value-font-color-focused: $treeselect-placeholder-font-color;
$treeselect-multi-value-margin-x: 0;
$treeselect-multi-value-margin-y: 2px;
$treeselect-multi-value-padding-x: $treeselect-padding;
$treeselect-multi-value-padding-y: 0;
$treeselect-multi-value-font-size: $treeselect-font-size-sm;
$treeselect-multi-value-font-color: $md-light-blue-600;
$treeselect-multi-value-item-bg: $md-blue-50;
$treeselect-multi-value-item-bg-new: $md-green-50;
$treeselect-multi-value-item-border-width: 1px;
$treeselect-multi-value-item-border-color: transparent;
$treeselect-multi-value-font-color-hover: $treeselect-multi-value-font-color;
$treeselect-multi-value-item-bg-hover: $treeselect-multi-value-item-bg;
$treeselect-multi-value-item-bg-new-hover: $treeselect-multi-value-item-bg-new;
$treeselect-multi-value-font-color-disabled: $md-grey-600;
$treeselect-multi-value-item-bg-disabled: $md-grey-100;
$treeselect-multi-value-item-font-color-control-disabled: #555;
$treeselect-multi-value-item-bg-control-disabled: #fff;
$treeselect-multi-value-item-border-color-control-disabled: #e5e5e5;
$treeselect-multi-value-remove-color: $treeselect-multi-value-font-color;
$treeselect-multi-value-remove-color-hover: $md-red-600;
$treeselect-multi-value-remove-size: 6px;
$treeselect-multi-value-divider-color: #fff;
$treeselect-limit-tip-font-color: $md-grey-400;
$treeselect-limit-tip-font-size: $treeselect-multi-value-font-size;
$treeselect-limit-tip-font-weight: 600;
$treeselect-single-input-font-size: inherit;
$treeselect-multi-input-font-size: $treeselect-multi-value-font-size;

$treeselect-menu-bg: $input-bg;
$treeselect-menu-padding-y: $treeselect-padding;
$treeselect-menu-line-height: $treeselect-control-single-height;
$treeselect-menu-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
$treeselect-control-menu-divider-color: #f2f2f2;

$treeselect-arrow-size: 9px;
$treeselect-arrow-color: #ccc;
$treeselect-arrow-color-hover: $md-grey-700;
$treeselect-arrow-transition-timing-function: $ease-out-expo;

$treeselect-x-size: 8px;
$treeselect-x-color: $treeselect-arrow-color;
$treeselect-x-color-hover: $danger;

$treeselect-option-bg-highlight: $primary;
$treeselect-option-selected-bg: $green;
$treeselect-option-selected-bg-hover: darken($treeselect-option-selected-bg, 7);
$treeselect-checkbox-size: $treeselect-icon-size;
$treeselect-checkbox-border-radius: $treeselect-border-radius-sm;
$treeselect-checkbox-color: $md-grey-300;
$treeselect-checkbox-color-highlight: $md-light-blue-600;
$treeselect-checkbox-border-color: $treeselect-checkbox-color;
$treeselect-checkbox-bg: $input-bg;
$treeselect-checkbox-border-color-hover: $treeselect-checkbox-color-highlight;
$treeselect-checkbox-bg-hover: $treeselect-checkbox-bg;
$treeselect-checkbox-indeterminate-border-color: $treeselect-checkbox-color-highlight;
$treeselect-checkbox-indeterminate-bg: $treeselect-checkbox-color-highlight;
$treeselect-checkbox-indeterminate-icon: "~@riophae/vue-treeselect/src/assets/checkbox-indeterminate.png";
$treeselect-checkbox-indeterminate-border-color-hover: $treeselect-checkbox-color-highlight;
$treeselect-checkbox-indeterminate-bg-hover: $treeselect-checkbox-color-highlight;
$treeselect-checkbox-checked-border-color: $treeselect-checkbox-color-highlight;
$treeselect-checkbox-checked-bg: $treeselect-checkbox-color-highlight;
$treeselect-checkbox-checked-icon: "~@riophae/vue-treeselect/src/assets/checkbox-checked.png";
$treeselect-icon-height: 8px;
$treeselect-checkbox-checked-border-color-hover: $treeselect-checkbox-color-highlight;
$treeselect-checkbox-checked-bg-hover: $treeselect-checkbox-color-highlight;
$treeselect-checkbox-disabled-border-color: $treeselect-checkbox-border-color;
$treeselect-checkbox-disabled-bg-color: darken($treeselect-checkbox-bg, 3%);
$treeselect-checkbox-disabled-indeterminate-icon: "~@riophae/vue-treeselect/src/assets/checkbox-indeterminate-disabled.png";
$treeselect-checkbox-disabled-checked-icon: "~@riophae/vue-treeselect/src/assets/checkbox-checked-disabled.png";
$treeselect-option-label-color: inherit;
$treeselect-option-label-color-disabled: rgba(0, 0, 0, 0.25);

$treeselect-loader-color-dark: $md-light-blue-600;
$treeselect-loader-color-light: $md-light-blue-100;
$treeselect-loader-animation-duration: 1.6s;
$treeselect-warning-icon-bg: $md-orange-600;
$treeselect-error-icon-bg: $md-red-600;
$treeselect-tip-color: $md-grey-600;
$treeselect-tip-font-size: $treeselect-font-size-sm;
$treeselect-retry-text-color: $md-light-blue-600;


/**
 * Mixins
 */

@mixin clickable(){
  cursor: pointer;
}

@mixin inputtable(){
  cursor: text;
}

@mixin forbidden(){
  cursor: not-allowed;
}

@mixin reset-cursor(){
  cursor: default;
}

@mixin rounded(){
  border-radius: 50%;
}

@mixin border-radius-top($size){
  border-top-left-radius: $size;
  border-top-right-radius: $size;
}

@mixin border-radius-bottom($size){
  border-bottom-left-radius: $size;
  border-bottom-right-radius: $size;
}

@mixin text-truncate(){
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin horizontal-padding($value){
  padding-left: $value;
  padding-right: $value;
}

@mixin vertical-padding($value){
  padding-top: $value;
  padding-bottom: $value;
}

@mixin icon(){
  display: block;
  margin: auto;
}

@mixin row(){
  @include horizontal-padding($treeselect-padding);

  display: table;
  table-layout: fixed;
  width: 100%;
}

@mixin cell(){
  display: table-cell;
  vertical-align: middle;
}

@mixin wide-cell(){
  @include cell();
  @include horizontal-padding($treeselect-padding);
  @include text-truncate();

  width: $treeselect-wide-cell-width;
}

@mixin narrow-cell(){
  @include cell();

  width: $treeselect-narrow-cell-width;
  text-align: center;
  line-height: 0;
}

/**
 * Helpers
 */

.vue-treeselect-helper-hide {
  display: none;
}

.vue-treeselect-helper-zoom-effect-off {
  transform: none !important; // stylelint-disable-line declaration-no-important
}


/**
 * Animations
 */

@keyframes vue-treeselect-animation-fade-in {
  0% {
    opacity: 0;
  }
}

@keyframes vue-treeselect-animation-bounce {
  0%, 100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}

@keyframes vue-treeselect-animation-rotate {
  100% {
    transform: rotate(360deg);
  }
}


/**
 * Transitions
 */

.vue-treeselect__multi-value-item--transition {
  &-enter-active,
  &-leave-active {
    transition-duration: $treeselect-transition-duration;
    transition-property: transform, opacity;
  }

  &-enter-active {
    transition-timing-function: $ease-out-circ;
  }

  &-leave-active {
    transition-timing-function: $ease-out-cubic;
    // trigger animation when element is removed
    position: absolute;
  }

  &-enter,
  &-leave-to {
    transform: scale(0.7);
    opacity: 0;
  }

  &-move {
    transition: $treeselect-transition-duration transform $ease-out-quart;
  }
}

.vue-treeselect__menu--transition {
  &-enter-active,
  &-leave-active {
    // to be overriden
  }

  &-enter,
  &-leave-to {
    // to be overriden
  }
}

.vue-treeselect__list--transition {
  &-enter-active,
  &-leave-active {
    // to be overriden
  }

  &-enter,
  &-leave-to {
    // to be overriden
  }
}


/**
 * Namespace
 */

.vue-treeselect {
  position: relative;
  text-align: left;

  [dir="rtl"] & {
    text-align: right;
  }

  div,
  span {
    box-sizing: border-box;
  }

  svg {
    fill: currentColor;
  }
}


/**
 * Control
 */

.vue-treeselect__control {
  @include row();

  height: $treeselect-control-height;
  border: $treeselect-control-border-width solid $treeselect-control-border-color;
  border-radius: $treeselect-control-border-radius;
  background: $treeselect-control-bg;
  transition-duration: $treeselect-transition-duration;
  transition-property: border-color, box-shadow, width, height, background-color, opacity;
  transition-timing-function: $ease-out-cubic;

  .vue-treeselect--disabled & {
    background-color: $treeselect-control-bg-disabled;
  }

  .vue-treeselect--open & {
    border-color: $treeselect-control-border-color-active;
  }

  .vue-treeselect--open.vue-treeselect--open-below & {
    @include border-radius-bottom(0);
  }

  .vue-treeselect--open.vue-treeselect--open-above & {
    @include border-radius-top(0);
  }
}

.vue-treeselect__value-container,
.vue-treeselect__multi-value {
  width: 100%;
  vertical-align: middle;
}

.vue-treeselect__value-container {
  display: table-cell;
  position: relative;

  .vue-treeselect--searchable:not(.vue-treeselect--disabled) & {
    // The real input is small and not covering the whole control.
    // We show an I-shape cursor to give user a hint that
    // clicking anywhere in the control will make the input focused.
    @include inputtable();
  }
}

.vue-treeselect__multi-value {
  display: inline-block;

  .vue-treeselect--has-value & {
    margin-bottom: $treeselect-padding;
  }
}

.vue-treeselect__placeholder,
.vue-treeselect__single-value {
  @include text-truncate();
  @include horizontal-padding($treeselect-padding);

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  line-height: $treeselect-control-inner-height;
  user-select: none;
  pointer-events: none;
}

.vue-treeselect__placeholder {
  color: $treeselect-placeholder-font-color;
}

.vue-treeselect__single-value {
  color: $treeselect-single-value-font-color;

  .vue-treeselect--focused.vue-treeselect--searchable & {
    color: $treeselect-single-value-font-color-focused;
  }

  .vue-treeselect--disabled & {
    // #274 Fix for IE
    position: static;
  }
}

.vue-treeselect__multi-value-item-container {
  display: inline-block;
  padding-top: $treeselect-padding;
  padding-right: $treeselect-padding;
  vertical-align: top;

  [dir="rtl"] & {
    padding-right: 0;
    padding-left: $treeselect-padding;
  }
}

.vue-treeselect__multi-value-item {
  @include clickable();

  display: inline-table;
  background: $treeselect-multi-value-item-bg;
  // Table elements do not have margin, so we set at the container element.
  padding: $treeselect-multi-value-margin-y $treeselect-multi-value-margin-x;
  border: $treeselect-multi-value-item-border-width solid $treeselect-multi-value-item-border-color;
  border-radius: $treeselect-border-radius-sm;
  color: $treeselect-multi-value-font-color;
  font-size: $treeselect-multi-value-font-size;
  vertical-align: top; // stylelint-disable-line plugin/declaration-block-no-ignored-properties

  .vue-treeselect:not(.vue-treeselect--disabled) &:not(.vue-treeselect__multi-value-item-disabled):hover &:not(.vue-treeselect__multi-value-item-new) &:not(.vue-treeselect__multi-value-item-new):hover {
    @include clickable();

    background: $treeselect-multi-value-item-bg-hover;
    color: $treeselect-multi-value-font-color-hover;
  }

  &.vue-treeselect__multi-value-item-disabled {
    @include reset-cursor();

    background: $treeselect-multi-value-item-bg-disabled;
    color: $treeselect-multi-value-font-color-disabled;
  }

  .vue-treeselect--disabled & {
    @include reset-cursor();

    background: $treeselect-multi-value-item-bg-control-disabled;
    border-color: $treeselect-multi-value-item-border-color-control-disabled;
    color: $treeselect-multi-value-item-font-color-control-disabled;
  }

  &.vue-treeselect__multi-value-item-new {
    background: $treeselect-multi-value-item-bg-new;
    &:hover {
      background: $treeselect-multi-value-item-bg-new;
    }
  }
}

.vue-treeselect__value-remove,
.vue-treeselect__multi-value-label {
  display: table-cell;
  padding: $treeselect-multi-value-padding-y $treeselect-multi-value-padding-x;
  vertical-align: middle;
}

.vue-treeselect__value-remove {
  color: $treeselect-multi-value-remove-color;
  padding-left: $treeselect-multi-value-padding-x;
  border-left: 1px solid $treeselect-multi-value-divider-color;
  line-height: 0;

  [dir="rtl"] & {
    border-left: 0 none;
    border-right: 1px solid $treeselect-multi-value-divider-color;
  }

  .vue-treeselect__multi-value-item:hover & {
    color: $treeselect-multi-value-remove-color-hover;
  }

  .vue-treeselect--disabled &,
  .vue-treeselect__multi-value-item-disabled & {
    display: none;
  }

  > svg {
    width: $treeselect-multi-value-remove-size;
    height: $treeselect-multi-value-remove-size;
  }
}

.vue-treeselect__multi-value-label {
  padding-right: $treeselect-multi-value-padding-x;
  white-space: pre-line;
  user-select: none;
}

.vue-treeselect__limit-tip {
  display: inline-block;
  padding-top: $treeselect-padding;
  padding-right: $treeselect-padding;
  vertical-align: top;

  [dir="rtl"] & {
    padding-right: 0;
    padding-left: $treeselect-padding;
  }
}

.vue-treeselect__limit-tip-text {
  @include reset-cursor();

  display: block;
  margin: $treeselect-multi-value-margin-y $treeselect-multi-value-margin-x;
  padding: ($treeselect-multi-value-padding-y + $treeselect-multi-value-item-border-width) 0;
  color: $treeselect-limit-tip-font-color;
  font-size: $treeselect-limit-tip-font-size;
  font-weight: $treeselect-limit-tip-font-weight;
}

.vue-treeselect__input-container {
  display: block;
  max-width: 100%;
  outline: none;

  .vue-treeselect--single & {
    font-size: $treeselect-single-input-font-size;
    height: 100%;
  }

  .vue-treeselect--multi & {
    display: inline-block;
    font-size: $treeselect-multi-input-font-size;
    vertical-align: top;
  }

  .vue-treeselect--searchable & {
    @include horizontal-padding($treeselect-padding);
  }

  .vue-treeselect--searchable.vue-treeselect--multi.vue-treeselect--has-value & {
    padding-top: $treeselect-padding;
    padding-left: 0;

    [dir="rtl"] & {
      padding-left: $treeselect-padding;
      padding-right: 0;
    }
  }

  .vue-treeselect--disabled & {
    display: none;
  }
}

.vue-treeselect__input,
.vue-treeselect__sizer {
  margin: 0;
  line-height: inherit;
  font-family: inherit;
  font-size: inherit;
}

.vue-treeselect__input {
  max-width: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  box-sizing: content-box;
  box-shadow: none;
  background: none transparent;
  line-height: 1;
  vertical-align: middle;

  &::-ms-clear {
    display: none;
  }

  .vue-treeselect--single & {
    width: 100%;
    height: 100%;
  }

  .vue-treeselect--multi & {
    @include vertical-padding($treeselect-multi-value-margin-y + $treeselect-multi-value-item-border-width);
  }

  .vue-treeselect--has-value & {
    line-height: inherit;
    vertical-align: top;
  }
}

.vue-treeselect__sizer {
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  height: 0;
  overflow: scroll;
  white-space: pre;
}

.vue-treeselect__x-container {
  @include narrow-cell();
  @include clickable();

  color: $treeselect-x-color;
  animation: $treeselect-transition-duration vue-treeselect-animation-fade-in $ease-out-circ;

  &:hover {
    color: $treeselect-x-color-hover;
  }
}

.vue-treeselect__x {
  width: $treeselect-x-size;
  height: $treeselect-x-size;
}

.vue-treeselect__control-arrow-container {
  @include narrow-cell();
  @include clickable();

  .vue-treeselect--disabled & {
    @include reset-cursor();
  }
}

.vue-treeselect__control-arrow {
  width: $treeselect-arrow-size;
  height: $treeselect-arrow-size;
  color: $treeselect-arrow-color;

  .vue-treeselect:not(.vue-treeselect--disabled) .vue-treeselect__control-arrow-container:hover & {
    color: $treeselect-arrow-color-hover;
  }

  .vue-treeselect--disabled & {
    opacity: 0.35;
  }
}

.vue-treeselect__control-arrow--rotated {
  transform: rotateZ(180deg);
}


/**
 * Menu
 */

.vue-treeselect__menu-container {
  position: absolute;
  left: 0;
  width: 100%;
  overflow: visible;
  transition: 0s;
  z-index: 90 !important;

  .vue-treeselect--open-below:not(.vue-treeselect--append-to-body) & {
    top: 100%;
  }

  .vue-treeselect--open-above:not(.vue-treeselect--append-to-body) & {
    bottom: 100%;
  }
}

.vue-treeselect__menu {
  @include reset-cursor(); // set to normal cursor since text is unselectable

  display: block;
  position: absolute;
  overflow-x: hidden;
  overflow-y: auto;
  // IE9 does not properly handle `width: 100%` with scrollbar when `box-sizing: border-box`
  width: auto;
  border: 1px solid $treeselect-control-border-color-active;
  background: $treeselect-menu-bg;
  line-height: $treeselect-menu-line-height;
  // https://css-tricks.com/snippets/css/momentum-scrolling-on-ios-overflow-elements/
  -webkit-overflow-scrolling: touch;

  .vue-treeselect--open-below & {
    @include border-radius-bottom(5px);

    top: 0;
    margin-top: (-$treeselect-control-border-width);
    border-top-color: $treeselect-control-menu-divider-color;
    box-shadow: $treeselect-menu-box-shadow;
  }

  .vue-treeselect--open-above & {
    @include border-radius-top(5px);

    bottom: 0;
    margin-bottom: (-$treeselect-control-border-width);
    border-bottom-color: $treeselect-control-menu-divider-color;
  }
}

.vue-treeselect__indent-level-0 .vue-treeselect__option {
  padding-left: $treeselect-padding;
}
[dir="rtl"] .vue-treeselect__indent-level-0 .vue-treeselect__option {
  padding-left: $treeselect-padding;
  padding-right: $treeselect-padding;
}
.vue-treeselect__indent-level-0 .vue-treeselect__tip {
  padding-left: $treeselect-padding + 1 * $treeselect-narrow-cell-width;
}
[dir="rtl"] .vue-treeselect__indent-level-0 .vue-treeselect__tip {
  padding-left: $treeselect-padding;
  padding-right: $treeselect-padding + 1 * $treeselect-narrow-cell-width;
}
.vue-treeselect__indent-level-1 .vue-treeselect__option {
  padding-left: $treeselect-padding + 1 * $treeselect-narrow-cell-width;
}
[dir="rtl"] .vue-treeselect__indent-level-1 .vue-treeselect__option {
  padding-left: $treeselect-padding;
  padding-right: $treeselect-padding + 1 * $treeselect-narrow-cell-width;
}
.vue-treeselect__indent-level-1 .vue-treeselect__tip {
  padding-left: $treeselect-padding + 2 * $treeselect-narrow-cell-width;
}
[dir="rtl"] .vue-treeselect__indent-level-1 .vue-treeselect__tip {
  padding-left: $treeselect-padding;
  padding-right: $treeselect-padding + 2 * $treeselect-narrow-cell-width;
}
.vue-treeselect__indent-level-2 .vue-treeselect__option {
  padding-left: $treeselect-padding + 2 * $treeselect-narrow-cell-width;
}
[dir="rtl"] .vue-treeselect__indent-level-2 .vue-treeselect__option {
  padding-left: $treeselect-padding;
  padding-right: $treeselect-padding + 2 * $treeselect-narrow-cell-width;
}
.vue-treeselect__indent-level-2 .vue-treeselect__tip {
  padding-left: $treeselect-padding + 3 * $treeselect-narrow-cell-width;
}
[dir="rtl"] .vue-treeselect__indent-level-2 .vue-treeselect__tip {
  padding-left: $treeselect-padding;
  padding-right: $treeselect-padding + 3 * $treeselect-narrow-cell-width;
}
.vue-treeselect__indent-level-3 .vue-treeselect__option {
  padding-left: $treeselect-padding + 3 * $treeselect-narrow-cell-width;
}
[dir="rtl"] .vue-treeselect__indent-level-3 .vue-treeselect__option {
  padding-left: $treeselect-padding;
  padding-right: $treeselect-padding + 3 * $treeselect-narrow-cell-width;
}
.vue-treeselect__indent-level-3 .vue-treeselect__tip {
  padding-left: $treeselect-padding + 4 * $treeselect-narrow-cell-width;
}
[dir="rtl"] .vue-treeselect__indent-level-3 .vue-treeselect__tip {
  padding-left: $treeselect-padding;
  padding-right: $treeselect-padding + 4 * $treeselect-narrow-cell-width;
}
.vue-treeselect__indent-level-4 .vue-treeselect__option {
  padding-left: $treeselect-padding + 4 * $treeselect-narrow-cell-width;
}
[dir="rtl"] .vue-treeselect__indent-level-4 .vue-treeselect__option {
  padding-left: $treeselect-padding;
  padding-right: $treeselect-padding + 4 * $treeselect-narrow-cell-width;
}
.vue-treeselect__indent-level-4 .vue-treeselect__tip {
  padding-left: $treeselect-padding + 5 * $treeselect-narrow-cell-width;
}
[dir="rtl"] .vue-treeselect__indent-level-4 .vue-treeselect__tip {
  padding-left: $treeselect-padding;
  padding-right: $treeselect-padding + 5 * $treeselect-narrow-cell-width;
}
.vue-treeselect__indent-level-5 .vue-treeselect__option {
  padding-left: $treeselect-padding + 5 * $treeselect-narrow-cell-width;
}
[dir="rtl"] .vue-treeselect__indent-level-5 .vue-treeselect__option {
  padding-left: $treeselect-padding;
  padding-right: $treeselect-padding + 5 * $treeselect-narrow-cell-width;
}
.vue-treeselect__indent-level-5 .vue-treeselect__tip {
  padding-left: $treeselect-padding + 6 * $treeselect-narrow-cell-width;
}
[dir="rtl"] .vue-treeselect__indent-level-5 .vue-treeselect__tip {
  padding-left: $treeselect-padding;
  padding-right: $treeselect-padding + 6 * $treeselect-narrow-cell-width;
}
.vue-treeselect__indent-level-6 .vue-treeselect__option {
  padding-left: $treeselect-padding + 6 * $treeselect-narrow-cell-width;
}
[dir="rtl"] .vue-treeselect__indent-level-6 .vue-treeselect__option {
  padding-left: $treeselect-padding;
  padding-right: $treeselect-padding + 6 * $treeselect-narrow-cell-width;
}
.vue-treeselect__indent-level-6 .vue-treeselect__tip {
  padding-left: $treeselect-padding + 7 * $treeselect-narrow-cell-width;
}
[dir="rtl"] .vue-treeselect__indent-level-6 .vue-treeselect__tip {
  padding-left: $treeselect-padding;
  padding-right: $treeselect-padding + 7 * $treeselect-narrow-cell-width;
}
.vue-treeselect__indent-level-7 .vue-treeselect__option {
  padding-left: $treeselect-padding + 7 * $treeselect-narrow-cell-width;
}
[dir="rtl"] .vue-treeselect__indent-level-7 .vue-treeselect__option {
  padding-left: $treeselect-padding;
  padding-right: $treeselect-padding + 7 * $treeselect-narrow-cell-width;
}
.vue-treeselect__indent-level-7 .vue-treeselect__tip {
  padding-left: $treeselect-padding + 8 * $treeselect-narrow-cell-width;
}
[dir="rtl"] .vue-treeselect__indent-level-7 .vue-treeselect__tip {
  padding-left: $treeselect-padding;
  padding-right: $treeselect-padding + 8 * $treeselect-narrow-cell-width;
}
.vue-treeselect__indent-level-8 .vue-treeselect__option {
  padding-left: $treeselect-padding + 8 * $treeselect-narrow-cell-width;
}
[dir="rtl"] .vue-treeselect__indent-level-8 .vue-treeselect__option {
  padding-left: $treeselect-padding;
  padding-right: $treeselect-padding + 8 * $treeselect-narrow-cell-width;
}
.vue-treeselect__indent-level-8 .vue-treeselect__tip {
  padding-left: $treeselect-padding + 8 * $treeselect-narrow-cell-width;
}
[dir="rtl"] .vue-treeselect__indent-level-8 .vue-treeselect__tip {
  padding-left: $treeselect-padding;
  padding-right: $treeselect-padding + 9 * $treeselect-narrow-cell-width;
}

.vue-treeselect__option {
  @include row();
  position: relative;
}

.vue-treeselect__option--highlight {
  background: $treeselect-option-bg-highlight;
  color: $white;

  &::after {
    content: 'Auswählen';
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    font-size: $treeselect-font-size * 0.9;
    padding-right: $treeselect-padding;
  }
}

.vue-treeselect__option--selected {
  // note that these should override `.vue-treeselect__option--highlight`
  .vue-treeselect--single & {
    background: $treeselect-option-selected-bg;
    color: $white;

    &:hover {
      background: $treeselect-option-selected-bg-hover;
    }
  }

  &::after {
    content: 'Ausgewählt';
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    font-size: $treeselect-font-size * 0.9;
    padding-right: $treeselect-padding;
  }
}

.vue-treeselect__option--hide {
  display: none;
}

.vue-treeselect__option-arrow-container,
.vue-treeselect__option-arrow-placeholder {
  @include narrow-cell();
}

.vue-treeselect__option-arrow-container {
  @include clickable();
}

.vue-treeselect__option-arrow {
  display: inline-block;
  width: $treeselect-arrow-size;
  height: $treeselect-arrow-size;
  color: $treeselect-arrow-color;
  vertical-align: middle;
  transition: $treeselect-transition-duration transform $treeselect-arrow-transition-timing-function;
  transform: rotateZ(-90deg);

  [dir="rtl"] & {
    transform: rotateZ(90deg);
  }

  .vue-treeselect__option-arrow-container:hover &,
  .vue-treeselect--branch-nodes-disabled .vue-treeselect__option:hover & {
    color: $white;
  }
}

.vue-treeselect__option-arrow--rotated {
  transform: rotateZ(0);

  [dir="rtl"] & {
    transform: rotateZ(0);
  }

  &.vue-treeselect__option-arrow--prepare-enter {
    transform: rotateZ(-90deg) !important; // stylelint-disable-line declaration-no-important

    [dir="rtl"] & {
      transform: rotateZ(90deg) !important; // stylelint-disable-line declaration-no-important
    }
  }
}

.vue-treeselect__label-container {
  @include cell();
  @include clickable();

  display: table;
  width: 100%;
  table-layout: fixed;
  color: $treeselect-option-label-color;

  .vue-treeselect__option--disabled & {
    cursor: default;

    color: $treeselect-option-label-color;
  }
}

.vue-treeselect__checkbox-container {
  display: table-cell;
  width: $treeselect-narrow-cell-width;
  min-width: $treeselect-narrow-cell-width;
  height: 100%;
  text-align: center;
  vertical-align: middle;
}

.vue-treeselect__checkbox {
  display: block;
  margin: auto;
  width: $treeselect-checkbox-size;
  height: $treeselect-checkbox-size;
  border-width: 1px;
  border-style: solid;
  border-radius: $treeselect-border-radius-sm;
  position: relative;
  transition: $treeselect-transition-duration all $ease-out-circ;
}

.vue-treeselect__check-mark,
.vue-treeselect__minus-mark {
  display: block;
  position: absolute;
  left: 1px;
  top: 1px;
  background-repeat: no-repeat;
  opacity: 0;
  transition: $treeselect-transition-duration all ease;
}

.vue-treeselect__minus-mark {
  width: $treeselect-icon-height;
  height: $treeselect-icon-height;
  background-image: url($treeselect-checkbox-indeterminate-icon);
  background-size: auto;
}
@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
  .vue-treeselect__minus-mark {
    background-image: url($treeselect-checkbox-indeterminate-icon);
  }
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .vue-treeselect__minus-mark {
    background-image: url($treeselect-checkbox-indeterminate-icon);
  }
}
@media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 288dpi) {
  .vue-treeselect__minus-mark {
    background-image: url($treeselect-checkbox-indeterminate-icon);
  }
}
.vue-treeselect__checkbox--indeterminate > .vue-treeselect__minus-mark {
  opacity: 1;
}
.vue-treeselect__checkbox--disabled .vue-treeselect__minus-mark {
  background-image: url($treeselect-checkbox-disabled-indeterminate-icon);
}
@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
  .vue-treeselect__checkbox--disabled .vue-treeselect__minus-mark {
    background-image: url($treeselect-checkbox-disabled-indeterminate-icon);
  }
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .vue-treeselect__checkbox--disabled .vue-treeselect__minus-mark {
    background-image: url($treeselect-checkbox-disabled-indeterminate-icon);
  }
}
@media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 288dpi) {
  .vue-treeselect__checkbox--disabled .vue-treeselect__minus-mark {
    background-image: url($treeselect-checkbox-disabled-indeterminate-icon);
  }
}

.vue-treeselect__check-mark {
  width: $treeselect-icon-height;
  height: $treeselect-icon-height;
  background-image: url($treeselect-checkbox-checked-icon);
  background-size: auto;
  -ms-transform: scaleY(1 / unit($treeselect-icon-height));
  transform: scaleY(1 / unit($treeselect-icon-height));
}
@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
  .vue-treeselect__check-mark {
    background-image: url($treeselect-checkbox-checked-icon);
  }
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .vue-treeselect__check-mark {
    background-image: url($treeselect-checkbox-checked-icon);
  }
}
@media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 288dpi) {
  .vue-treeselect__check-mark {
    background-image: url($treeselect-checkbox-checked-icon);
  }
}
.vue-treeselect__checkbox--checked > .vue-treeselect__check-mark {
  opacity: 1;
  -ms-transform: scaleY(1);
  transform: scaleY(1);
}
.vue-treeselect__checkbox--disabled .vue-treeselect__check-mark {
  background-image: url($treeselect-checkbox-disabled-checked-icon);
}
@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
  .vue-treeselect__checkbox--disabled .vue-treeselect__check-mark {
    background-image: url($treeselect-checkbox-disabled-checked-icon);
  }
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .vue-treeselect__checkbox--disabled .vue-treeselect__check-mark {
    background-image: url($treeselect-checkbox-disabled-checked-icon);
  }
}
@media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 288dpi) {
  .vue-treeselect__checkbox--disabled .vue-treeselect__check-mark {
    background-image: url($treeselect-checkbox-disabled-checked-icon);
  }
}

.vue-treeselect__checkbox--unchecked {
  border-color: $treeselect-checkbox-border-color;
  background: $treeselect-checkbox-bg;

  .vue-treeselect__label-container:hover & {
    border-color: $treeselect-checkbox-border-color-hover;
    background: $treeselect-checkbox-bg-hover;
  }
}

.vue-treeselect__checkbox--indeterminate {
  border-color: $green;
  background: $green;

  .vue-treeselect__label-container:hover & {
    border-color: darken($green, 7);
    background: darken($green, 7);;
  }
}

.vue-treeselect__checkbox--checked {
  border-color: $green;
  background: $green;

  .vue-treeselect__label-container:hover & {
    border-color: darken($green, 7);;
    background: darken($green, 7);;
  }
}

.vue-treeselect__checkbox--disabled {
  border-color: $treeselect-checkbox-disabled-border-color;
  background-color: $treeselect-checkbox-disabled-bg-color;

  .vue-treeselect__label-container:hover & {
    border-color: $treeselect-checkbox-disabled-border-color;
    background-color: $treeselect-checkbox-disabled-bg-color;
  }
}


.vue-treeselect__label {
  @include text-truncate();

  display: table-cell;
  padding-left: $treeselect-padding;
  max-width: 100%;
  vertical-align: middle;
  cursor: inherit; // override user agent style

  [dir="rtl"] & {
    padding-left: 0;
    padding-right: $treeselect-padding;
  }
}

.vue-treeselect__count {
  margin-left: $treeselect-padding;
  font-weight: 400;
  opacity: 0.6;

  [dir="rtl"] & {
    margin-left: 0;
    margin-right: $treeselect-padding;
  }
}

.vue-treeselect__tip {
  @include row();

  color: $treeselect-tip-color;
}

.vue-treeselect__tip-text {
  @include wide-cell();

  font-size: $treeselect-tip-font-size;
}

.vue-treeselect__retry {
  .vue-treeselect__error-tip & {
    @include clickable();

    margin-left: $treeselect-padding;
    font-style: normal;
    font-weight: 600;
    text-decoration: none;
    color: $treeselect-retry-text-color;

    [dir="rtl"] & {
      margin-left: 0;
      margin-right: $treeselect-padding;
    }
  }
}

.vue-treeselect__icon-container {
  @include narrow-cell();

  .vue-treeselect--single & {
    padding-left: $treeselect-padding;

    [dir="rtl"] & {
      padding-left: 0;
      padding-right: $treeselect-padding;
    }
  }
}

.vue-treeselect__icon-warning {
  @include icon();
  @include rounded();

  position: relative;
  width: $treeselect-icon-size;
  height: $treeselect-icon-size;
  background: $treeselect-warning-icon-bg;

  &::after {
    display: block;
    position: absolute;
    content: "";
    left: 5px;
    top: 2.5px;
    width: 2px;
    height: 1px;
    border: 0 solid #fff;
    border-top-width: 5px;
    border-bottom-width: 1px;
  }
}

.vue-treeselect__icon-error {
  @include icon();
  @include rounded();

  $stroke-length: 6px;

  position: relative;
  width: $treeselect-icon-size;
  height: $treeselect-icon-size;
  background: $treeselect-error-icon-bg;

  &::before,
  &::after {
    display: block;
    position: absolute;
    content: "";
    background: #fff;
    transform: rotate(45deg);
  }

  &::before {
    width: $stroke-length;
    height: 2px;
    left: 3px;
    top: 5px;
  }

  &::after {
    width: 2px;
    height: $stroke-length;
    left: 5px;
    top: 3px;
  }
}

.vue-treeselect__icon-loader {
  @include icon();

  position: relative;
  width: $treeselect-icon-size;
  height: $treeselect-icon-size;
  text-align: center;
  animation: $treeselect-loader-animation-duration vue-treeselect-animation-rotate linear infinite;

  &::before,
  &::after {
    @include rounded();

    position: absolute;
    content: "";
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    opacity: 0.6;
    animation: $treeselect-loader-animation-duration vue-treeselect-animation-bounce ease-in-out infinite;
  }

  &::before {
    background: $treeselect-loader-color-dark;
  }

  &::after {
    background: $treeselect-loader-color-light;
    animation-delay: -($treeselect-loader-animation-duration / 2);
  }
}


/**
 * Menu Portal
 */

.vue-treeselect__menu-placeholder {
  display: none;
}

.vue-treeselect__portal-target {
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  height: 0;
  width: 0;
  padding: 0;
  margin: 0;
  border: 0;
  overflow: visible;
  box-sizing: border-box;
}

.firm-detail-appraiser {
  min-height: 410px;
}

.empty-root-node-warning {
  margin-left: 15px;
  font-style: italic;

  &::before {
    @include inticon;
    content: $inticon-warning;
    padding-right: 5px;
  }
}

.vue-treeselect__multi-value-item-container {
  display: none;
}
