$costunit-insurance-highlight-color: #dbe6c4 !default;
$costunit-amount-active-color: #000 !default;
$costunit-amount-open-color: #f00 !default;
$costunit-amount-paid-color: #008000 !default;

#costUnitSelect {
  td.amountNetTotal,
  td.amountPaid,
  td.amountOpen {
    table {
      border-collapse: collapse;
      width: 100%;
    }

    td {
      border-top: 1px solid #fff;

      &:last-child {
        text-align: right;
      }
    }

    tr:first-child td {
      border-top: 0;
    }

    tr:last-child td {
      font-weight: bold;
    }

    tr.insurancePayment {
      background-color: $costunit-insurance-highlight-color;
      border: 1px solid #ddd;
    }
  }

  td.hide {
    width: 140px;
    padding: 0;

    td {
      text-align: center;

      ul {
        display: inline-block;
      }
    }
  }

  td.dateReceipt,
  td.datePaid,
  td.costUnit,
  td.amountNetTotal,
  td.amountPaid,
  td.amountOpen {
    padding-left: 8px;
    padding-right: 8px;
  }

  td.costUnit {
    white-space: nowrap;
  }

  td.paymentComment {
    max-width: 450px;
  }
}

.costUnitLegends {
  .legends,
  .summary {
    width: 49%;
  }

  .legends {
    float: left;
  }

  .costUnitSummary {
    float: right;
  }

  .legend {
    display: inline-block;
    width: 24px;
    text-align: center;
    margin-right: 5px;

    &.legend-insurance {
      background-color: $costunit-insurance-highlight-color;
      position: relative;
      height: 8px;
      top: 2px;
    }
  }
}

div.costUnitSummary {
  > table > tbody {
    > tr {
      > th,
      td {
        text-align: right;
        padding-right: 20px;

        &.label {
          padding-right: 40px;
        }

        span.insuranceFigure {
          font-weight: bold;
          text-decoration: underline;
        }
      }

      > th {
        font-weight: normal;
      }

      > td {
        font-weight: bold;
      }

      &.summaryOpen > td {
        border-top: 1px dotted $black;
      }
    }
  }
}

.costUnitPaid {
  font-weight: bold;
  color: $costunit-amount-paid-color;
}

.costUnitOpen {
  font-weight: bold;
  color: $costunit-amount-open-color;
}

#costUnitInfo .listing {
  min-width: 30%;
  margin: 0 1%;

  &#record {
    min-width: 15%;
  }

  .listingHeader {
    margin-top: 10px;
    font-weight: bold;
  }

  td {
    padding: 2px 0 0;

    &.listingLabel {
      padding-right: 10px;
    }

    &.listingContent {
      padding-right: 20px;
    }
  }
}
