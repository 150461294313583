.card {
  &.card-foldable {
    background-color: $body-bg;
    border: 0;

    .foldable-header {
      color: $primary;
      outline: none;
      box-shadow: 0 0 7px rgba(0, 0, 0, 0.2);
      background-color: $white;
      margin-bottom: 1rem;
      padding: 10px 10px 10px 25px;
      position: relative;

      &:hover {
        text-decoration: none;
      }

      i {
        margin-left: 10px;
      }

      .foldable-headline {
        padding-left: 10px;
      }

      &::before {
        @include inticon;
        content: $inticon-angle-right;
        position: absolute;
        top: 50%;
        left: $grid-gutter-width / 2;
        transform: translateY(-50%) rotate(90deg);
        transition: transform 0.2s linear;
      }

      &.collapsed {
        background-color: $primary;
        color: $white;

        &::before {
          transform: translateY(-50%) rotate(0deg);
        }
      }

      &.state::after {
        @include inticon;
        font-size: 20px;
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
        background-color: $white;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &.state-valid::after {
        content: $inticon-completed;
        color: $secondary;
      }

      &.state-invalid::after {
        content: $inticon-warning;
        color: $danger;
      }
    }


    > div > .card {
      box-shadow: 0 0 7px rgba(0, 0, 0, 0.2);

      > .card-body {
        padding: 0;
      }
    }
  }

  &.microportal {
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.2);
    color: $tertiary;

    .card-body {
      padding-top: 35px;
      padding-bottom: 35px;
    }

    a {
      outline: none;
    }

    h3 {
      font-style: normal;
      font-size: 18px;
      margin-bottom: 0;
      color: $primary;
    }

    i {
      font-size: 60px;
      margin-bottom: 30px;
    }

    &.active {
      background-color: $secondary;
      border-color: $secondary;
      color: $white;

      h3 {
        color: $white;
      }

      .icon-container {
        color: $white;
      }
    }

    &.sm {
      .card-body {
        padding-top: 20px;
        padding-bottom: 20px;
      }

      h3 {
        font-size: 12px;
      }

      i {
        font-size: 40px;
        margin-bottom: 20px;
      }
    }
  }
}

.card-foldable {
  margin-bottom: 1rem;

  .foldable-header {
    margin-bottom: 0.5rem;
  }
}

.collapse-body {
  padding: $form-group-margin-bottom;
}

.card .help {
  position: absolute;
  display: inline-block;
  top: 10px;
  right: 10px;
  border: 1px solid $gray-300;
  width: 22px;
  height: 22px;
  text-align: center;
  font-size: 12px;
  line-height: 22px;
  margin: 0;
  border-radius: 50%;
  color: $gray-700;
  cursor: pointer;
  font-weight: bold;
  font-style: normal;
}

.card-body {
  padding-bottom: 0;

  .inticon {
    position: absolute;
    color: lighten(desaturate(#44a226, 20), 20);
    font-size: 68px;
    top: 50px;
    left: 20px;
    z-index: 19;
  }

  .card-inner-row {
    display: flex;
    justify-content: flex-end;
    position: relative;
    z-index: 20;
    margin-bottom: $form-group-margin-bottom;

    .column {
      flex: 1 1 auto;
      padding: 0 5px;
      text-align: right;
      min-width: 0;

      > span {
        font-size: 28px;

        &.bold {
          font-weight: bold;
        }
      }
    }

    p {
      margin: 0;
      font-size: 12px;
      font-weight: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 100%;

      small {
        display: block;
        font-size: 0.8em;
      }
    }
  }
}

.card-header {
  background-color: transparent;
  border-bottom: 0 none;
  color: #032f3f;
  text-transform: uppercase;
  font-weight: bold;
  padding-bottom: 0;
}

.analytics-card.card-wrapper {
  // For loading indicator!
  position: relative;
}

.overflow-x-hidden {
    overflow-x: hidden;
}
