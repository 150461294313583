$body-bg: #fff;
$body-color: #000;

$input-bg: #fff;
$input-border-color: #ced4da;

$tertiary: #87857d;
$light: #f7f7f8;
$theme-colors: (
  "tertiary": $tertiary
);

$btn-jquery-color: #333;
$btn-jquery-bg: #f7f7f8;
$btn-jquery-border-color: #cdccc7;

$messages-sidebar-width: 280px;
$sidebar-background-color: #f7f7f8;
$sidebar-transition-duration: 0.4s;
$sidebar-transition-ease: ease-in-out;

$state-font-size: 10px;
$state-badge-width: 30px;
$state-badge-margin-right: 2px;
$state-icon-font-size: 16px;
$state-icon-font-width: 24px;

$multiselect-tag-padding: 6px;
$multiselect-tag-padding-left: 9px;
$multiselect-select-width: 20px;
$multiselect-single-height: 16px; // Adjust manually with fontsize
$multiselect-option-height: 20px;

$treeselect-control-single-height: 30px;

// stylelint-disable value-keyword-case
$font-family-sans-serif: Arial, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`

$link-color: #0b5e73;
$link-hover-color: lighten($link-color, 15%) !default;

$h1-font-size: $font-size-base * 1.6;
$h2-font-size: $font-size-base * 1.4;
$h3-font-size: $font-size-base;
$h4-font-size: $font-size-base;
$h5-font-size: $font-size-base;

$enable-rounded: true;
$input-border-radius: 0;
$multiselect-tag-border-radius: 0;

@import "~@scss/imports/variables";

$nav-tabs-border-color: $input-border-color;
$nav-tabs-link-hover-border-color: darken($btn-jquery-border-color, 7.5);
$nav-tabs-link-active-bg: $primary;
$nav-tabs-link-active-color: $white;
$nav-tabs-link-active-border-color: $primary;

$state-color-neutral: #a5a39b;
$state-color-red: #e12b97;
$state-color-yellow: #ffaf00;
$state-color-green: #55ab24;
