:root {
  --fc-button-bg-color: #{$primary};
  --fc-button-border-color: #{$gray-400};
  --fc-today-bg-color: #{$highlighted};
}

$reminder-color-code-0-color: #0b5e73;
$reminder-color-code-1-color: $state-color-neutral;
$reminder-color-code-2-color: $state-color-yellow;
$reminder-color-code-3-color: $state-color-green;
$reminder-color-code-4-color: $state-color-red;

#noteCalendar {
  position: relative;

  .loading-overlay {
    top: 3vh;
  }

  .fc-daygrid-event-dot {
    display: none;
  }

  .fc-event {
    background-color: $primary;
    border: 1px solid $primary;

    &:hover {
      color: #fff;
    }

    &.reminder-color-code-0 {
      background-color: $reminder-color-code-0-color;
      border-color: $reminder-color-code-0-color;
    }

    &.reminder-color-code-1 {
      background-color: $reminder-color-code-1-color;
      border-color: $reminder-color-code-1-color;
    }

    &.reminder-color-code-2 {
      background-color: $reminder-color-code-2-color;
      border-color: $reminder-color-code-2-color;
    }

    &.reminder-color-code-3 {
      background-color: $reminder-color-code-3-color;
      border-color: $reminder-color-code-3-color;
    }

    &.reminder-color-code-4 {
      background-color: $reminder-color-code-4-color;
      border-color: $reminder-color-code-4-color;
    }
  }

  .fc-col-header-cell-cushion {
    cursor: pointer;
  }

  .fc .fc-button {
    height: auto;
    padding: 8px;
    border: 0;

    .fc-icon {
      margin-top: -3px;
    }
  }

  .fc .fc-button-primary {
    &:disabled {
      display: none;
    }

    &:focus {
      border: 0;
      box-shadow: none;
    }
  }

  .fc-customDayViewButton-button {
    margin-left: 0;
  }

  .fc-button.fc-showAllButtonGroup {
    button {
      height: 34px;
    }

    > :first-child {
      border-top-left-radius: 0.25em;
      border-bottom-left-radius: 0.25em;
    }

    > :last-child {
      border-top-right-radius: 0.25em;
      border-bottom-right-radius: 0.25em;
    }
  }

  .fc-dayGridDay-view .fc-daygrid-block-event .fc-event-title {
    /* Padding of events */
    padding: 6px 4px;
  }

  .fc-dayGridDay-view .fc-daygrid-day-events .fc-daygrid-event,
  .fc-dayGridDay-view .fc-daygrid-day-events .fc-daygrid-more-link {
    /* Vertical distance between events */
    margin-top: 2px;
  }

  .fc-header-toolbar {
    > .fc-toolbar-chunk:nth-child(2) {
      // Center align title relative to the parent. the flex items left and right can change in size.
      // Assumes that it is always the third item.
      position: absolute;
      left: 50%;  // this is relative to the parent
      transform: translateX(-50%);  // this is relative to width of the current element
      margin-left: -15px;

      .fc-toolbar-title {
        text-align: center;
      }
    }
  }
}

.nc24Form {
  .reminderColorCode {
    .buttonset .form-check {
      display: inline-block;

      label {
        &:hover {
          opacity: 0.5;
        }

        &.ui-state-active {
          border: 1px solid #cdccc7;
          padding: 2px;
          background-color: inherit;

        }

        .ui-button-text {
          visibility: hidden;
          padding: 4px;
        }
      }
    }

    #reminderColorCode-0 + label {
      background-color: $reminder-color-code-0-color;
    }

    #reminderColorCode-1 + label {
      background-color: $reminder-color-code-1-color;
    }

    #reminderColorCode-2 + label {
      background-color: $reminder-color-code-2-color;
    }

    #reminderColorCode-3 + label {
      background-color: $reminder-color-code-3-color;
    }

    #reminderColorCode-4 + label {
      background-color: $reminder-color-code-4-color;
    }
  }
}
