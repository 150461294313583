.receipt-filter-short {
  min-width: 140px;
}

.receipt-filter-long {
  min-width: 200px;
}

.receipt-page-select {
  min-width: 6em;
}
