@import "~@scss/partials/recordstate";

.record-state-badge {
  height: 21px;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  &.has-icon {
    height: auto;
    padding: 2px;
  }
}
